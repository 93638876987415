const ClipboardJs = require("clipboard");
import { MDXProvider } from "@mdx-js/react"


export const onClientEntry = () => {
  new ClipboardJs("div.copy-btn");
};


const mdxComponents = {
  blockquote: props => {
    if (!props.children || !props.children.props || !props.children.props.children) {
      return null;
    }
    return (
    <callout-info className="aside">
      {props.children.props.children}
    </callout-info>)
  },
}


export const wrapRootElement = ({ element }) => {
  return <MDXProvider  components={mdxComponents}>
    {element}</MDXProvider>;
};